<template>
  <v-container max-width="800">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-card class="elevation-12">
          <v-snackbar
            v-model="snackbar"
            centered
            :color="color"
            :timeout="timeout"
          >
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-text>
          <p class="text-center font-weight-light font-italic mt-8" v-if="changearea">After changing the password, you should login again before you continue</p>
          </v-card-text>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="oldpassword"
                label="Old Password"
                name="oldpassword"
                type="password"
                dense
                color="grey"
                outlined
                class="mt-10"
                filled
              />
              <validation-provider
                rules="required"
                vid="confirmation"
                v-slot="{ errors }"
                name="New Password">
              <v-text-field
                v-model="newpassword"
                label="New Password"
                name="New Password"
                type="password"
                dense
                :hint="hintMesssage"
                persistent-hint
                :error-messages="errors"
                color="grey"
                outlined
                filled
              />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                rules="confirmed:confirmation"
                name="Confirmed Password">
              <v-text-field
                v-model="confirmpassword"
                label="Confirm Password"
                name="Confirmed Password"
                dense
                :error-messages="errors"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append.prevent="show1 = !show1"
                outlined
                color="grey"
                filled
              />
              </validation-provider>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              width="100px"
              @click="changePassword"
            >
              Submit
            </v-btn>
            <v-spacer />
            <v-btn
              outlined
              link
              text
              @click="backToLogin"
            >
            Back To Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      hintMesssage: 'password complexity, at least: 8 characters, one capital letter and avoid sequential numbers(123...)',
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
      show: false,
      show1: false,
      changearea: true,
      snackbar: false,
      color: null,
      text: null,
      timeout: 10000
    }
  },
  computed: {
    ...mapState('store', ['currentUser'])
  },

  methods: {

    changePassword () {
      this.$userService.changePassword(this.currentUser.id, this.oldpassword, this.newpassword)
        .then(response => {
          if (response.status === 'OK') {
            this.snackbar = true
            this.color = 'green'
            this.text = 'Your password has been changed successfully. Please Login again'
            this.changearea = false
            setTimeout(() => {
              this.backToLogin()
            }, 7000)
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.snackbar = true
            this.text = 'Error! ' + response.message
            this.color = 'red'
          }
        })
    },
    backToLogin () {
      // this.setAuthentication(false)
      this.$router.push('/login')
    }
  }

}

</script>
